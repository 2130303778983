import React, { useMemo, useRef } from 'react';

// Externals
import mapboxgl from 'mapbox-gl';

// Helpers
import { useLegendColor } from '../../../../../helpers/legend_color';

// Models
import { MeasurementDTO } from 'app/models/measurement.dto';
import { StationDTO } from 'app/models/station.dto';
import { useIntl } from 'react-intl';
import { Marker } from 'react-map-gl';
import { Circle } from 'react-feather';
import withLocale from '../../../../../../i18n/with_locale';
import moment from 'moment';

interface Props {
  measurement: MeasurementDTO;
  station: StationDTO;
}

const CustomMarker: React.FC<Props> = ({ measurement, station }) => {
  const [getLegend] = useLegendColor();
  const markerRef = useRef<mapboxgl.Marker | null>(null);
  const intl = useIntl();
  const popup = useMemo(() => {
    const popupObject = new mapboxgl.Popup({
      maxWidth: 'auto'
    });

    popupObject.setHTML(
      `<div style='display: flex; flex-direction: column; flex: 1;'>
       <p>${intl.formatMessage(
        { id: 'Map.Marker.Period', defaultMessage: 'Periode: {b} - {e}' },
        {
          b: `<b>${moment(measurement.startDate.toDate()).format('DD/MM/YYYY')}</b>`,
          e: `<b>${moment(measurement.endDate.toDate()).format('DD/MM/YYYY')}</b>`,
        }
      )} </p>
    
       <p>${intl.formatMessage(
        { id: 'Map.Marker.MeasureValue', defaultMessage: 'Mesure: {measure}' },
        {
          measure: `<b>${measurement.value.toFixed(2)}</b> µg/m3`,
        }
      )}</p>
      </div>`
    );
    return popupObject;
  }, [intl, measurement.value, station.name]);

  return (
    <Marker
      longitude={station.coordinates.longitude}
      latitude={station.coordinates.latitude}
      ref={markerRef}
      popup={popup}
    >
      <Circle
        fill={getLegend(measurement.value)}
        size={16}
        style={{ cursor: 'pointer' }}
        stroke={getLegend(measurement.value)}
        strokeWidth={1}
      />
    </Marker>
  );
};

export default withLocale(CustomMarker);
