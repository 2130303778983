import React, { useMemo, useRef } from 'react';

// Externals
import withLocale from 'i18n/with_locale';

// Components
// Helpers
import { useLegendColor } from 'app/helpers/legend_color';

// Material
// Models
import { MeasurementDTO } from 'app/models/measurement.dto';
import { StationDTO } from 'app/models/station.dto';
import { Marker } from 'react-map-gl';
import { Circle } from 'react-feather';
import moment from 'moment';
import mapboxgl from 'mapbox-gl';
import { StationTypes } from '../../../../../models/station.type';
import { useIntl } from 'react-intl';

interface Props {
  measurement: MeasurementDTO;
  station: StationDTO;
}

const CustomMarker: React.FC<Props> = ({ measurement, station }) => {
  const [getLegend] = useLegendColor();
  const markerRef = useRef<mapboxgl.Marker | null>(null);
  const intl = useIntl();

  const getMarkerName = (station: StationDTO) => {
    switch (station.type) {
      case StationTypes.School:
        return intl.formatMessage(
          { id: 'Map.Marker.SchoolStation', defaultMessage: 'École : {name}' },
          {
            name: station.name
          }
        );

      case StationTypes.StationBE:
        return intl.formatMessage(
          { id: 'Map.Marker.StationBE', defaultMessage: 'Station BE : {name}' },
          {
            name: station.name
          }
        );
      case StationTypes.Other:
        return intl.formatMessage(
          { id: 'Map.Marker.OtherStation', defaultMessage: 'Autre : {name}' },
          {
            name: station.name
          }
        );
      case StationTypes.Citizen:
        return intl.formatMessage(
          { id: 'Map.Marker.CitizenStation', defaultMessage: 'Station citoyenne' },
          {
            name: station.name
          }
        );
      default:
        return null;
    }
  };

  const popup = useMemo(() => {
    const popupObject = new mapboxgl.Popup({
      maxWidth: 'auto'
    });

    popupObject.setHTML(
      `<div style='display: flex; flex-direction: column; flex: 1;'>
       <p>${getMarkerName(station)}</p>
       <p>${intl.formatMessage(
        { id: 'Map.Marker.Period', defaultMessage: 'Periode: {b} - {e}' },
        {
          b: `<b>${moment(measurement.startDate.toDate()).format('DD/MM/YYYY')}</b>`,
          e: `<b>${moment(measurement.endDate.toDate()).format('DD/MM/YYYY')}</b>`,
        }
      )} </p>
       <p>${intl.formatMessage(
        { id: 'Map.Marker.MeasureValue', defaultMessage: 'Mesure: {measure}' },
        {
          measure: `<b>${measurement.value.toFixed(2)}</b> µg/m3`,
        }
      )}</p>
      </div>`
    );
    return popupObject;
  }, [intl, measurement.value, station.name]);

  return (
    <Marker
      longitude={station.coordinates.longitude}
      latitude={station.coordinates.latitude}
      ref={markerRef}
      popup={popup}
    >

      <Circle
        fill={getLegend(measurement.value)}
        size={16}
        style={{ cursor: 'pointer' }}
        stroke={getLegend(measurement.value)}
        strokeWidth={1}
      />
    </Marker>
  );
};

export default withLocale(CustomMarker);
