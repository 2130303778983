import React, { FC, useCallback, useState } from 'react';

// Components
import ShareButton from './share_button';

// Externals
import moment from 'moment';
import { Map as MapView, NavigationControl, Source, Layer, MapRef } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

// Models
import LangButton from './lang_button';
import { StationDTO } from '../models/station-v3.dto';
import withLocale from '../../../../i18n/with_locale';
import Legend from './legend';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { useSettings } from '../../../helpers/settings-provider';

import mapConfig from '../data/config';
import Filter from './filter';
import { StationType, StationTypes } from 'app/models/station.type';
import { clusterCountLayer, clusterLayer, unclusteredPointLayer } from './layers';
import mapboxgl from 'mapbox-gl';
import { useIntl } from 'react-intl';

const Map: FC<{ locale?: string }> = ({ locale }) => {
  const [showData, setShowData] = React.useState({
    [StationTypes.School]: true,
    [StationTypes.Hospital]: true
  });

  const setShowDataType = (type: StationType, value: boolean) => {
    setShowData({ ...showData, [type]: value });
  };

  moment.locale(locale);

  const stationsRef = useFirestore().collection(mapConfig.dataCollection);

  const { data: stationData } = useFirestoreCollectionData<StationDTO>(stationsRef, {
    initialData: []
  });
  const { thresholds } = useSettings();

  const intl = useIntl();

  const mapRef = React.useRef<MapRef>(null);

  const [cursor, setCursor] = useState<string>('auto');

  const onMouseEnter = useCallback(() => setCursor('pointer'), []);
  const onMouseLeave = useCallback(() => setCursor('auto'), []);

  const mapOnClick = useCallback(
    (event) => {
      // If the user clicked on one of your markers, get its information.
      const features = mapRef.current!.queryRenderedFeatures(event.point, {
        layers: ['unclustered-point'] // replace with your layer name
      });
      if (!features.length) {
        return;
      }

      const feature = features[0] as any;

      const station = stationData.find((s) => s.id === feature.properties.id);
      if (!station) {
        return;
      }

      const measurement = station.measurements![0].value.toFixed(2);

      /**
       * Create a popup, specify its options
       * and properties, and add it to the map.
       */
      new mapboxgl.Popup({
        offset: [0, -15],
        maxWidth: 'auto'
      })
        .setLngLat(feature.geometry.coordinates)
        .setHTML(
          `<div style='display: flex; flex-direction: column; flex: 1;'>
            <p style='font-weight:bold;'>${station.name}</p>
            <p>${intl.formatMessage(
            { id: 'Map.V3.Marker.MeasureValue', defaultMessage: 'Mesure: {measure}' },
            { measure: `<b>${measurement}</b> µg/m3` }
          )}</p>
          </div>`
        )
        .addTo(mapRef.current!.getMap());
    },
    [intl, stationData]
  );

  return (
    <div>
      <Legend />
      {/* <Filter showData={showData} setShowData={setShowDataType} /> */}
      <LangButton />
      <ShareButton />
      {/* <YearPicker selectedDate={selectedDate} setSelectedDate={setDisplayDate} /> */}
      <MapView
        cursor={cursor}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        interactiveLayerIds={['unclustered-point']}
        onClick={mapOnClick}
        ref={mapRef}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
        initialViewState={{
          longitude: 4.3545358,
          latitude: 50.8268688,
          zoom: 11
        }}
        style={{ height: '100vh', width: '100vw' }}
        mapStyle={process.env.REACT_APP_MAPBOX_STYLE}
      >
        <Source
          type="geojson"
          cluster={true}
          clusterMaxZoom={12}
          clusterRadius={50}
          data={{
            type: 'FeatureCollection',
            features: stationData
              .filter((station) => showData[station.type])
              .map((station) => ({
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [station.coordinates.longitude, station.coordinates.latitude]
                },
                properties: {
                  id: station.id,
                  title: station.name,
                  type: station.type,
                  no2value: station.measurements![0].value
                }
              }))
          }}
        >
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer(thresholds)} />
        </Source>

        <NavigationControl position={'top-left'} />
      </MapView>
    </div>
  );
};

export default withLocale(Map);
